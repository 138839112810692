<template>
  <div class="mt-5">
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-end mt-5">
        <v-btn
          color="primary"
          dark
          @click="addMansoryItem"
          v-if="!showNewMansoryImageForm"
        >
          Aggiungi slideshow
        </v-btn>
      </v-col>

      <!-- FORM -->
      <template v-if="showNewMansoryImageForm">
        <v-col cols="12">
          <v-row class="wrapper-gallery mt-2">
            <v-col cols="3">
              <v-file-input
                accept="image/*"
                label="Immagine"
                v-model="form.image"
                @change="onFileChange"
              ></v-file-input>
            </v-col>
            <v-col cols="3">
              <template v-if="this.imageUrl">
                <img class="image-preview" v-if="imageUrl" :src="imageUrl" />
              </template>
            </v-col>
          </v-row>

          <div>
            <v-text-field label="Titolo" v-model="form.title"></v-text-field>
          </div>

          <div>
            <v-text-field
              label="Categoria"
              v-model="form.category"
            ></v-text-field>
          </div>

          <b>{{ label }}</b>
          <br />
          <ckeditor
            :config="editorConfig"
            :editor="editor"
            v-model="form.description"
            @ready="onReady"
          ></ckeditor>
        </v-col>

        <v-col cols="12" class="d-flex justify-end">
          <v-btn color="secondary" dark class="mr-2 submit" @click="cancel"
            >Annulla</v-btn
          >
          <v-btn color="primary" dark class="mb-2 submit" @click="submit"
            >Salva</v-btn
          >
        </v-col>
      </template>
    </v-row>
    <template v-if="!showNewMansoryImageForm">
      <draggable
        v-model="sortableItems"
        class="v-tabs__container"
        group="images"
        @start="drag = true"
        @end="drag = false"
      >
        <v-row
          v-for="item in this.block.content.mansory_images"
          :key="item.id"
          class="item d-flex align-stretch"
        >
          <v-col cols="3">
            <img :src="item.image_url" />
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="12">
                <label>Titolo</label>
                <div>{{ item.title }}</div>
              </v-col>

              <v-col cols="12">
                <label>Categoria</label>
                <div>{{ item.caption }}</div>
              </v-col>

              <v-col cols="12">
                <div v-html="item.description"></div>
              </v-col>

              <v-col
                cols="12"
                class="mt-5 d-flex justify-end align-end actions"
              >
                <v-btn
                  color="red"
                  dark
                  class="mr-4 submit"
                  @click="confirmDelete(item)"
                >
                  Elimina
                </v-btn>
                <v-btn color="primary" dark class="submit" @click="edit(item)">
                  Modifica
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </draggable>
    </template>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import {
  CREATE_MANSORY_IMAGE,
  UPDATE_MANSORY_IMAGE,
  DELETE_MANSORY_IMAGE,
  SORT_MANSORY_IMAGE,
} from "@/core/services/store/mansory_blocks.module";
import draggable from "vuedraggable";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/it";

export default {
  components: {
    ckeditor: CKEditor.component,
    draggable,
  },

  props: {
    mansoryBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },

    label: {
      type: String,
      default: "",
    },
  },

  computed: {
    sortableItems: {
      get() {
        return (this.block.content.mansory_images || []).sort((a, b) => {
          return a - b;
        });
      },
      set(value) {
        let image_ids = value.map((item) => {
          return item.id;
        });

        let params = {
          mansory_image_ids: image_ids,
        };

        this.$store.dispatch(SORT_MANSORY_IMAGE, params).then(() => {
          this.$emit("change");
        });
      },
    },
  },

  data() {
    return {
      block: null,

      form: {
        id: null,
        category: null,
        description: null,
        image: null,
        title: null,
      },
      imageUrl: null,

      editor: DecoupledEditor,
      editorConfig: {
        language: "it",
        addMainLanguageTranslationsToAllAssets: true,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "alignment",
          ],
        },
      },
      isFormValid: false,
      showNewMansoryImageForm: false,
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    cleanUpForm() {
      this.form = {
        id: null,
        title: null,
        category: null,
        image: null,
        description: null,
      };
      this.imageUrl = null;
    },

    addMansoryItem() {
      this.cleanUpForm();
      this.showNewMansoryImageForm = true;
      this.$emit("editing", this.showNewMansoryImageForm);
    },

    onFileChange(file) {
      this.validateForm();

      if (file != null) {
        this.imageUrl = URL.createObjectURL(file);
      } else {
        this.imageUrl = null;
      }

      this.form.image = file;
    },

    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    validateForm() {
      this.isFormValid =
        this.form.category &&
        this.form.category.length > 0 &&
        this.form.title &&
        this.form.title.length > 0 &&
        this.imageUrl != null &&
        this.form.description &&
        this.form.description.length > 0;
    },

    cancel() {
      this.showNewMansoryImageForm = false;
      this.$emit("editing", this.showNewMansoryImageForm);
    },

    edit(item) {
      this.form.id = item.id;
      this.form.image = null;
      this.form.title = item.title;
      this.form.category = item.caption;
      this.form.description = item.description;
      this.imageUrl = item.image_url;

      this.showNewMansoryImageForm = true;
      this.$emit("editing", this.showNewMansoryImageForm);
    },

    confirmDelete(item) {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete(item);
    },

    delete(item) {
      this.$store.dispatch(DELETE_MANSORY_IMAGE, item.id).then(() => {
        this.cleanUpForm();

        this.showNewMansoryImageForm = false;
        this.$emit("editing", this.showNewMansoryImageForm);

        this.showSnack({
          text: "Showcase eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.$emit("delete");
      });
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        if (this.form.id == null) {
          /// CREATE

          let params = {
            mansory_block_id: this.block.content.id,
            title: this.form.title,
            caption: this.form.category,
            description: this.form.description,
            attachment: this.form.image,
          };

          let formData = this.jsonToFormData({ mansory_image: params });

          this.$store.dispatch(CREATE_MANSORY_IMAGE, formData).then(() => {
            this.cleanUpForm();

            this.showNewMansoryImageForm = false;
            this.$emit("editing", this.showNewMansoryImageForm);

            this.showSnack({
              text: "Showcase creato con successo.",
              color: "success",
              timeout: 3500,
            });

            this.$emit("create");
          });
        } else {
          /// UPDATE

          let params = {
            title: this.form.title,
            caption: this.form.category,
            description: this.form.description,
          };
          if (this.form.image != null) {
            params["attachment"] = this.form.image;
          }

          let formData = this.jsonToFormData({ mansory_image: params });

          this.$store
            .dispatch(UPDATE_MANSORY_IMAGE, {
              id: this.form.id,
              params: formData,
            })
            .then(() => {
              this.cleanUpForm();

              this.showNewMansoryImageForm = false;
              this.$emit("editing", this.showNewMansoryImageForm);

              this.showSnack({
                text: "Showcase aggiornato con successo.",
                color: "success",
                timeout: 3500,
              });

              this.$emit("update");
            });
        }
      } else {
        this.showSnack({
          text: "Compila tutti i campi del form!",
          color: "error",
          timeout: 3500,
        });
      }
    },
  },

  created() {
    this.block = this.mansoryBlock;
  },
};
</script>


<style scoped>
.new-item-btn {
  border: 2px solid #00254d;
  height: 100px;
  width: 100px;
  line-height: 94px;
  text-align: center;
}

.new-item-btn i::before {
  color: #00254d;
}

.image-preview {
  width: 100%;
}

.item {
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
}

.item img {
  width: 100%;
}

.item label {
  font-size: 11px !important;
  margin-bottom: 0 !important;
}

/* .item .actions {
  bottom: 0;
  position: absolute;
  right: 0;
  widows: 100%;
} */
</style>