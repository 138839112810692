<template>
  <div data-app>
    <div class="row">
      <div class="col-md-12 offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
        <h1 class="title text-center mt-5 mb-5">Showcase</h1>

        <template v-if="page.blocks.length > 0">
          <div v-for="block in page.blocks" :key="block.id">
            <TextBlock
              v-if="block.block_type == 'TextBlock' && !editing"
              :textBlock="block"
              :deletable="false"
              label="Descrizione"
            />

            <MansoryBlock
              v-if="block.block_type == 'MansoryBlock'"
              :mansoryBlock="block"
              :deletable="false"
              :editable="true"
              @create="fetchShowcase"
              @update="fetchShowcase"
              @delete="fetchShowcase"
              @editing="onEditing"
            />
          </div>
        </template>

        <v-row class="mt-5">
          <v-col class="d-flex justify-end mt-5">
            <v-btn color="primary" dark class="mb-2" @click="submit()">
              Salva pagina
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
      

<script>
import { mapActions } from "vuex";
import { SHOWCASE_DETAIL } from "@/core/services/store/showcases.module";
import { UPDATE_PAGES } from "@/core/services/store/pages.module";
import { UPDATE_BLOCK } from "@/core/services/store/blocks.module";

import MansoryBlock from "@/view/components/Block/MansoryBlock.vue";
import TextBlock from "@/view/components/Block/TextBlock.vue";

export default {
  components: {
    MansoryBlock,
    TextBlock,
  },

  data() {
    return {
      editing: false,
      page: {},
    };
  },

  created() {},

  methods: {
    initialize() {
      this.fetchShowcase();
    },

    fetchShowcase() {
      this.page.blocks = [];

      this.$store.dispatch(SHOWCASE_DETAIL).then((data) => {
        this.page = data.page;
      });
    },

    onEditing(newValue) {
      this.editing = newValue;
    },

    showcaseUpdated(block_id, blocks) {
      this.page.blocks = [];

      this.page.blocks.forEach((item) => {
        if (item.id == block_id) {
          item = blocks;
        }
      });
    },

    methods: {
      ...mapActions("snackbar", ["showSnack"]),

      submit() {
        this.$store
          .dispatch(UPDATE_PAGES, {
            id: this.page.id,
            data: {
              page_title: this.page.page_title,
              title: this.page.title,
            },
          })
          .then(async () => {
            this.page.blocks.forEach((block) => {
              var dataBlock = {};

              if (block.block_type == "TextBlock")
                dataBlock["text"] = this.page.blocks[0].content.text;

              this.$store.dispatch(UPDATE_BLOCK, {
                id: block.id,
                data: {
                  block: dataBlock,
                },
              });
            });
          })
          .then(() => {
            this.fetchTerms();
            this.showSnack({
              text: "Pagina Termini e condizioni salvata con successo.",
              color: "success",
              timeout: 3500,
            });
          })
          .catch((error) => {
            alert(error.message);
          });
      },
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>
